<!-- Stepper  -->
<template>
    <div class="stepper flex items-center pt-6 pb-6 w-full gap-8">
        <div
        v-for="(item, key) in props.steps"
        :key="item+key" 
        :class="[
            'flex gap-4 items-center',
            key === 0 || key < props.steps.length - 1 ? 'flex-grow' : '' 
        ]">
            <div class="text-and-image flex gap-4">
            <div 
            :class="[
                'h-14 w-14 rounded-full flex  icon justify-center items-center',
                key <= current ? 'text-white bg-red-principal' : 'text-grey-real bg-hensa-form '
            ]">
                <icon :data="item.icon" height="18" width="18" class="inline-blok"/> 
            </div>
                <div class="text-section mt-1">
                <h4 :class="[
                    'text-sm',
                    key <= current ? 'text-blue-text' : 'text-grey-real'
                ]">{{ $t('inscription.step') + (key + 1) }}</h4>
                <h4 
                :class="[
                    'text-xl font-semibold',
                    key <=  current ? 'text-black' : 'text-grey-text'
                ]">{{ $t(`${item.label}`)}}</h4>
            </div>
            </div>
            <div v-if="key === 0 || key < props.steps.length - 1 " 
            :class="[
                'flex-grow h-one',
                current > key ? 'bg-red-principal' : 'bg-black-thin'
            ]">
        </div>
        </div>
    </div>
</template>

<script setup>
import ic_parent from '../../assets/icons/ic_parent.svg'    
import { defineProps } from 'vue'    
// Define props for get step 

const props = defineProps({
    steps: {
        type: Array,
        required: true,
        default: function () {
            return [
            {
                label: 'inscription.civilState',
                icon: ic_parent
            },
            {
                label: 'inscription.step',
                icon: ic_parent
            },
            {
                label: 'inscription.step',
                icon: ic_parent
            } 
        ]
        }
    },
    current: {
        type: Number,
        default: 0
    }
})

</script>
