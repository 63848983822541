<template>
    <div class="inscription">
        <!-- Header for Inscrtion -->
        <div class="header h-28 border-b flex items-center pl-16 pr-16 gap-10">
            <a class="img-section block" href="/">
                <img class="h-16" src="../../assets/images/webp/logo.webp"/>
            </a>
            <div class="divider h-20 w-1 border-l"></div>
            <div class="title-section">
                <h3 class="text-2xl">{{ $t('inscription.title') }}</h3>
            </div>
        </div>
        <div class="content md:w-1/2 w-5/6 m-auto pt-10 pb-10">
            <h3 class="text-lg text-grey-principal">{{ $t('inscription.pleaseEditForm') }}</h3>
            <!-- Step section -->
            <div class="step md:block hidden">
                <Stepper :steps="steps" :current="current"></Stepper>
            </div>

            <!-- Step content section -->
            <div class="step-content mt-4">
                <h3 class="text md:text-3xl text-2xl font-semibold">{{ $t(`${steps[current].realLabel || steps[current].label}`) }}</h3>
                <h3 class="text-xl font-thin mt-1 text-grey-principal">{{ $t(`${steps[current].description}`) }}</h3>
                <!-- Step real -->
                <div class="mt-6"> 
                    <PersonStep v-if="current === 0" @person-info="getPersonInfo"></PersonStep>
                    <ParentInformation v-if="current === 1" @parental-info="getParentalInfo"></ParentInformation>
                    <JoinFileStep v-if="current === 2"></JoinFileStep>
                </div>
            </div>

            <!-- Next step button -->
            <div class="flex w-full justify-end mt-8">
                <div
                @click="checkAndGo"
                 class="h-14 rounded-xl bg-blue-principal cursor-pointer text-white flex text-xl justify-center items-center md:w-1/4 w-full">{{ $t('inscription.next') }}</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Stepper from '../../components/globals/Stepper.vue';
import ic_parent from '../../assets/icons/ic_parent.svg'
import ic_person from '../../assets/icons/ic_person.svg'
import ic_files from '../../assets/icons/ic_files.svg'
import { ref } from 'vue';
import PersonStep from '../../components/sign/PersonStep.vue';
import ParentInformation from '../../components/sign/ParentInformation.vue';
import JoinFileStep from '../../components/sign/JoinFileStep.vue';
import { useI18n } from 'vue-i18n';


// Current step 
const current = ref(0)

// Initialise i18n 
const { t } = useI18n()

// All inscription information: 
const allInscriptionData = ref({ personal: null, parents: null, files: null })

// Define step 
const steps = [
    {
        label: 'inscription.civilState',
        icon: ic_person,
        description: 'inscription.desc'
    },
    {
        label: 'inscription.parents',
        icon: ic_parent,
        description: 'inscription.desc',
        realLabel: 'inscription.parentInformation'
    },
    {
        label: 'inscription.files',
        icon: ic_files,
        description: 'inscription.desc',
        realLabel: 'inscription.joinFile'
    },
]

// Check and go next step
function checkAndGo() {
    // Check personnal information
    if (current.value === 0) {
        if (allInscriptionData.value.personal !== null && checkObject(allInscriptionData.value.personal)) {
            current.value++
        } else {
        //     notify({
        //     text: t('inscription.fillAllFields'),
        //     type: 'error',
        //     duration: 5000
        // })
        alert('error');
        }
    }
    // Check parental information
    else if (current.value === 1) {
        if (allInscriptionData.value.parents !== null && checkObject(allInscriptionData.value.parents)) {
            current.value++
        } else {
        //     console.log('see-q', allInscriptionData.value.parents)
        //     notify({
        //     text: t('inscription.fillAllFields'),
        //     type: 'error',
        //     duration: 5000
        // })
        alert('error');
        }
    }
    
}

// Check if object all key are not empty
function checkObject(obj) {
    for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
            return false;
        }
    }
    return true;
}


// Get personal infomation.
function getPersonInfo(answer) {
    allInscriptionData.value.personal = answer;
    console.log(allInscriptionData.value.personal)
}

// Get parental Information
function getParentalInfo(answer) {
    allInscriptionData.value.parents = answer;
    console.log(allInscriptionData.value.parents)
}



</script>
