<template>
    <div class="step">
        <!-- School level -->
        <div class="school-level flex md:gap-0 gap-6 flex-wrap">
            <div 
            class="md:w-1/3 w-full md:p-2"
            v-for="(item, key) in schoolLevel"
             :key="item+key"  
           >
             <div
             @click="changePosition(key)"
             :class="[
                'border-2 rounded-lg  flex items-center justify-center h-32 cursor-pointer',
                info.niveau === key ? 'border-red-principal' : ''
             ]">
                <div class="text-center"> 
                <h3 class="text-base text-grey-ultraThin font-light">{{ $t('inscription.level') }} </h3>
                <h3 class="text-2xl text-black-principal font-semibold">{{ $t('inscription.licence')  + ' ' +  (key + 1)}} </h3>
                </div>
                <div class="absolute -mt-16 md:-mr-48 -mr-72" v-if="info.niveau === key">
                    <icon :data="ic_success" height="20" width="20" class="inline-blok" original/> 
                </div>
             </div>
            </div>
        </div>
        <!-- Filiere -->
        <div class="mt-8">
            <label for="filiere" class="text-sm inline-block"> {{ $t('training.subtitle') }} *</label>
            <select v-model="info.filiere" id="filiere" class="h-14 w-full border rounded-xl mt-2 p-2 text-base"> 
                <option v-for="(item, key) in allTraining" :value="$t(`${item}`)" :key="item+key">{{ $t(`${item}`) }}</option>
            </select>
        </div>
        <!-- Personnal Information -->
        <div class="personnal-info flex flex-wrap mt-6">
            <div class="name md:w-1/2 w-full">
                <label for="name" class="text-sm inline-block"> {{ $t('inscription.name') }} *</label>
                <input v-model="info.name" :placeholder="$t('inscription.name')" type="text" name="name" id="name" class="h-14 w-full border rounded-xl p-2 text-base mt-2">
            </div>
            <div class="surname md:w-1/2 w-full md:pl-4">
                <label for="surname" class="text-sm inline-block"> {{ $t('inscription.surname') }} *</label>
                <input v-model="info.surname" :placeholder="$t('inscription.surname')" type="text" name="surname" id="surname" class="h-14 w-full border rounded-xl p-2 text-base mt-2">
            </div>
        </div>
        <!-- Phone Number -->
        <div class="mt-6">
            <label for="phone" class="text-sm inline-block"> {{ $t('inscription.phone') }} *</label>
            <input v-model="info.phone" placeholder="229 62 92 20 91" type="text" name="phone" id="phone" class="h-14 w-full border rounded-xl p-2 text-base mt-2">
        </div>
        <!-- Email -->
        <div class="mt-6">
            <label for="email" class="text-sm inline-block"> {{ $t('inscription.email') }} *</label>
            <input v-model="info.email" placeholder="you@company.com" type="email" name="email" id="email" class="h-14 w-full border rounded-xl p-2 text-base mt-2">
        </div>
        <!-- Birth Information -->
        <div class="personnal-info flex flex-wrap mt-6">
            <div class="birthday md:w-1/2 w-full">
                <label for="birthday" class="text-sm inline-block"> {{ $t('inscription.birthday') }} *</label>
                <input v-model="info.birthday" placeholder="../../.." type="date" name="birthday" id="birthday" class="h-14 w-full border rounded-xl p-2 text-base mt-2">
            </div>
            <div class="birthPlace md:w-1/2 w-full md:pl-4">
                <label for="birthPlace" class="text-sm inline-block"> {{ $t('inscription.birthPlace') }} *</label>
                <input v-model="info.birthPlace" placeholder="Ex:Cotonou" type="text" name="birthPlace" id="birthPlace" class="h-14 w-full border rounded-xl p-2 text-base mt-2">
            </div>
        </div>
        <!-- Sexe -->
        <div class="mt-6">
            <label for="sexe" class="text-sm inline-block"> {{ $t('inscription.sexe') }} *</label>
            <select v-model="info.sexe" id="sexe" class="h-14 w-full border rounded-xl mt-2 p-2 text-base"> 
                <option v-for="(item, key) in allSexualties" :value="$t(`${item}`)" :key="item+key">{{ $t(`${item}`) }}</option>
            </select>
        </div>
        <!-- Country Information -->
        <div class="country-info flex flex-wrap mt-6">
            <div class="country md:w-1/2 w-full">
                <label for="country" class="text-sm inline-block"> {{ $t('inscription.country') }} *</label>
                <input v-model="info.country" placeholder="Ex:Bénin" type="text" name="country" id="country" class="h-14 w-full border rounded-xl p-2 text-base mt-2">
            </div>
            <div class="nationnality md:w-1/2 w-full md:pl-4">
                <label for="nationnality" class="text-sm inline-block"> {{ $t('inscription.nationnality') }} *</label>
                <input v-model="info.nationnality" placeholder="Ex: Beninois" type="text" name="nationnality" id="nationnality" class="h-14 w-full border rounded-xl p-2 text-base mt-2">
            </div>
        </div>
        <!-- Photo section -->
        <div class="photo-section mt-6">
            <label for="photo" class="text-sm inline-block"> {{ $t('inscription.addImage') }} *</label>
            <FilePicker class="mt-2" @selectedImage="getFile"></FilePicker>
        </div>
        <!-- Comming from -->
        <div class="mt-6">
            <label for="commingSchool" class="text-sm inline-block"> {{ $t('inscription.commingSchool') }} *</label>
            <input v-model="info.commingSchool" placeholder="Ex: Monteigne" type="text" name="commingSchool" id="commingSchool" class="h-14 w-full border rounded-xl p-2 text-base mt-2">
        </div>
    </div>
</template>

<script setup>
import { ref, watch, defineEmits } from 'vue';
import ic_success from '../../assets/icons/ic_success.svg'   
import { useI18n } from 'vue-i18n';
import FilePicker from '../globals/FilePicker.vue'; 

// Define translator
const { t } = useI18n()
 
// All training
const allTraining = [
    'training.digitalProjectManager',
    'training.webDevelopper',
    'training.ecom',
    'training.itTech',
    'training.twin',
    'training.srit',
    'training.bfa',
    'training.audit',
    'training.projectManagement',
] 

// All sexuality
const allSexualties = [
    'inscription.masculin',
    'inscription.feminin'
]

// selected item
const info = ref({
    niveau: 0,
    filiere: t('training.digitalProjectManager'),
    phone: '',
    name: '',
    surname: '',
    email: '',
    birthday: '',
    birthPlace: '',
    sexe: t('inscription.masculin'),
    country: '',
    nationnality: '',
    commingSchool: '',
    photo: null
})


// Init emit 
const emit = defineEmits(['personInfo'])


// School Level   
const schoolLevel = [0,1,2]

// change item
function changePosition(position) {
    info.value.niveau = position;
} 

function getFile(answer) {
    info.value.photo = answer;
}

// Watch when info has some change 
watch(info.value, (value) => {
    emit('personInfo', value)
})

</script>
