<template>
    <div class="parent-information">
        <!-- Dad Information -->
        <div class="personnal-info flex md:flex-nowrap flex-wrap gap-6 mt-6">
            <div class="dadName md:w-1/2 w-full">
                <label for="dadName" class="text-sm inline-block"> {{ $t('inscription.dadName') }} *</label>
                <input v-model="info.dadName" :placeholder="$t('inscription.dadName')" type="text" name="dadName"
                    id="dadName" class="h-14 w-full border rounded-xl p-2 text-base mt-2">
            </div>
            <div class="dadSurname md:w-1/2 w-full">
                <label for="dadSurname" class="text-sm inline-block"> {{ $t('inscription.dadSurname') }} *</label>
                <input v-model="info.dadSurname" :placeholder="$t('inscription.dadSurname')" type="text"
                    name="dadSurname" id="dadSurname" class="h-14 w-full border rounded-xl p-2 text-base mt-2">
            </div>
        </div>
        <!-- Dad profession -->
        <div class="mt-6">
            <label for="dadProfession" class="text-sm inline-block"> {{ $t('inscription.dadProfession') }} *</label>
            <input v-model="info.dadProfession" placeholder="Docteur" type="text" name="dadProfession"
                id="dadProfession" class="h-14 w-full border rounded-xl p-2 text-base mt-2">
        </div>
        <!-- Dad phone and email -->
        <div class="personnal-info flex md:flex-nowrap flex-wrap gap-6 mt-6">
            <div class="dadPhone md:w-1/2 w-full">
                <label for="dadPhone" class="text-sm inline-block"> {{ $t('inscription.phone') }} *</label>
                <input v-model="info.dadPhone" placeholder="229 62 92 20 91" type="text" name="dadPhone" id="dadPhone"
                    class="h-14 w-full border rounded-xl p-2 text-base mt-2">
            </div>
            <div class="dadEmail md:w-1/2 w-full">
                <label for="dadEmail" class="text-sm inline-block"> {{ $t('inscription.email') }} *</label>
                <input v-model="info.dadEmail" placeholder="dad@gmail.com" type="email" name="dadEmail" id="dadEmail"
                    class="h-14 w-full border rounded-xl p-2 text-base mt-2">
            </div>
        </div>
        <!-- Dad city -->
        <div class="mt-6">
            <label for="dadCity" class="text-sm inline-block"> {{ $t('inscription.city') }} *</label>
            <input v-model="info.dadCity" placeholder="Ex: Cotonou" type="text" name="dadCity" id="dadCity"
                class="h-14 w-full border rounded-xl p-2 text-base mt-2">
        </div>
        <div class="mt-8 mb-6 h-one w-full bg-grey-thin"></div>
        <!-- Mum Information -->
        <div class="personnal-info  flex md:flex-nowrap flex-wrap gap-6 mt-6">
            <div class="mumName md:w-1/2 w-full">
                <label for="mumName" class="text-sm inline-block"> {{ $t('inscription.mumName') }} *</label>
                <input v-model="info.mumName" :placeholder="$t('inscription.mumName')" type="text" name="mumName"
                    id="mumName" class="h-14 w-full border rounded-xl p-2 text-base mt-2">
            </div>
            <div class="mumSurname md:w-1/2 w-full">
                <label for="mumSurname" class="text-sm inline-block"> {{ $t('inscription.mumSurname') }} *</label>
                <input v-model="info.mumSurname" :placeholder="$t('inscription.mumSurname')" type="text"
                    name="mumSurname" id="mumSurname" class="h-14 w-full border rounded-xl p-2 text-base mt-2">
            </div>
        </div>
                <!-- Mum profession -->
            <div class="mt-6">
            <label for="mumProfession" class="text-sm inline-block"> {{ $t('inscription.mumProfession') }} *</label>
            <input v-model="info.mumProfession" placeholder="Directrice" type="text" name="mumProfession"
                id="mumProfession" class="h-14 w-full border rounded-xl p-2 text-base mt-2">
        </div>
                <!-- Mum phone and email -->
                <div class="personnal-info flex md:flex-nowrap flex-wrap gap-6 mt-6">
            <div class="mumPhone md:w-1/2 w-full">
                <label for="mumPhone" class="text-sm inline-block"> {{ $t('inscription.phone') }} *</label>
                <input v-model="info.mumPhone" placeholder="229 62 92 20 91" type="text" name="mumPhone" id="mumPhone"
                    class="h-14 w-full border rounded-xl p-2 text-base mt-2">
            </div>
            <div class="mumEmail md:w-1/2 w-full">
                <label for="mumEmail" class="text-sm inline-block"> {{ $t('inscription.email') }} *</label>
                <input v-model="info.mumEmail" placeholder="mum@gmail.com" type="email" name="mumEmail" id="mumEmail"
                    class="h-14 w-full border rounded-xl p-2 text-base mt-2">
            </div>
        </div>
            <!-- Mum city -->
                <div class="mt-6">
            <label for="mumCity" class="text-sm inline-block"> {{ $t('inscription.city') }} *</label>
            <input v-model="info.mumCity" placeholder="Ex: Cotonou" type="text" name="mumCity" id="mumCity"
                class="h-14 w-full border rounded-xl p-2 text-base mt-2">
        </div>
    </div>
</template>

<script setup>
import { ref, defineEmits, watch } from "vue";

// all emit 
const emit = defineEmits(["parentalInfo"])


// Perental information
const info = ref({
    dadName: '',
    dadSurname: '',
    dadProfession: '',
    dadPhone: '',
    dadEmail: '',
    dadCity: '',
    mumName: '',
    mumSurname: '',
    mumProfession: '',
    mumPhone: '',
    mumEmail: '',
    mumCity: ''
})

// Watch and emit info
watch(info.value, (value) => {
    emit("parentalInfo", value)
})
</script>
