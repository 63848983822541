<template>
    <div class="file-picker flex gap-3 h-40">
        <div v-if="selectedFile" :class="[
            'image-view-section  w-1/4 flex items-center justify-center  h-full rounded-xl',
            allFiles[0].type.includes('image') ? '' : 'border'
            ]">
            <div v-if="allFiles.length > 1" class=" bg-black-thin font-bold absolute z-10 w-10 h-10 text-white flex items-center justify-center text-sm  rounded-full m-1">+ {{ allFiles.length - 1 }}</div>
            <img v-if="allFiles[0].type.includes('image')" :src="selectedFile" class="h-full w-full rounded-xl object-cover"/>
            <!-- <icon v-else :data="ic_files" height="24" width="24" class="inline-blok" original/> -->
            <embed
            v-else :src="selectedFile" 
            style="max-height: 100%; min-height: 100%"
            class="h-full w-full  rounded-xl object-content"/>
        </div>
        <div class="container-real cursor-pointer flex gap-3 justify-center items-center border h-40 w-full rounded-xl">
            <label :for="props.name" class="flex flex-col w-full h-full items-center justify-center cursor-pointer gap-2">
                <div class="icon h-12 w-12 rounded-full flex items-center justify-center bg-hensa-form">
                        <icon :data="ic_file_picker" height="24" width="24" class="inline-blok" original/> 
                </div>
                <div class="text flex gap-1">
                    <h3 class="text-base text-blue-link">{{ $t('inscription.clickForAdd') }}</h3>
                    <h3 class="text-base text-grey-ultraThin">{{ $t('inscription.orDrop') }}</h3>
                </div>
                <div class="file-type">
                    <h3 class="text-base text-grey-ultraThin">{{ $t('inscription.fileType') }}</h3>
                </div>
                <input v-if="props.multiple" @change="getFile" :accept="props.format"  :id="props.name" multiple type="file"  class="w-full h-full hidden"/>
                <input v-if="!props.multiple" @change="getFile" :accept="props.format"  :id="props.name" type="file"  class="w-full h-full hidden"/>
            </label>
        </div>
    </div>
</template>

<script setup>
import { ref, defineEmits, defineProps } from 'vue';
// import ic_file_picker from '../../assets/icons/ic_file_picker.svg'
// import ic_files from '../../assets/icons/ic_files.svg'

// Selected file
const selectedFile = ref(null);

// All file when is multiple
const allFiles = ref(null)

// Define props
const props = defineProps({
    name: {
        type: String,
        default: 'file-picker'
    },
    multiple: {
        type: Boolean,
        default: false
    },
    format: {
        type: String,
        default: 'image/*'
    }
})

// All emit
const emit = defineEmits(['selectedImage']) 

// get file
async function getFile(event) {
   selectedFile.value = URL.createObjectURL(event.target.files[0]);
   const files = event.target.files
   allFiles.value = event.target.files;
   console.log('see-tun', event.target.files[0])
   emit('selectedImage', files)
}
</script>
